import axios from '@/libs/api.request'

/**
 * 获取注册配置
 * 
 */
 export const getDconfig = (params) => {
  return axios.request({
    url: 'publics/dealerpc.dealerpc/dconfig',
    method: 'get',
    params:params
  })
}
/**
 * 上传图片
 * 
 */
 export const doupload = (data) => {
  return axios.request({
    url: 'publics/dealerpc.dealerpc/doupload',
    method: 'post',
    data
  })
}
/**
 * 获取省
 * 
 */
 export const getProvince = (params) => {
  return axios.request({
    url: 'publics/dealerpc.dealerpc/province',
    method: 'get',
    params:params
  })
}
/**
 * 获取市
 * 
 */
 export const getCity = (params) => {
  return axios.request({
    url: 'publics/dealerpc.dealerpc/city',
    method: 'get',
    params:params
  })
}
/**
 * 获取区
 * 
 */
 export const getDistrict = (params) => {
  return axios.request({
    url: 'publics/dealerpc.dealerpc/district',
    method: 'get',
    params:params
  })
}


/**
 * 提交
 * 
 */
 export const storeReg = (data) => {
  return axios.request({
    url: 'publics/dealerpc.dealerpc/storeReg',
    method: 'post',
    data
  })
}


/**
 * 提交
 * 
 */
 export const dealerReg = (data) => {
  return axios.request({
    url: 'publics/dealerpc.dealerpc/dealerReg',
    method: 'post',
    data
  })
}